/* Global Styles */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: inherit; /* Ensures all elements inherit the correct font */
}

/* Apply macOS Default Font Globally */
body, html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #1F2022;
  color: #fff;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* Root Element */
#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

/* Main App Container */
.app {
  width: 100%;
  max-width: 1000px;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1F2022;
}

/* Headings & Text Defaults */
h1, h2, h3, h4, h5, h6, p, span, a, button, input, textarea {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  body, html {
    font-size: 14px;
  }
  #root {
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  }
  .app {
    max-height: 4000px;
    padding: 20px;
  }
}
